import React from "react"
import { observer } from "mobx-react-lite"
import {
  EuiForm,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiRadioGroup,
  EuiPanel,
  EuiImage,
  EuiDescriptionList,
} from "@elastic/eui"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXSingleButtonFooter from "../../components/layout/footer/hailcx-single-button-footer"
import { navigate } from "gatsby"
import Images from "../../images"
import { useStore } from "../../stores"

const CancelConfirmPage = () => {
  const { customerStore } = useStore()
  const data = {
    title: "Cancellation",
    buttonContent: "Back",
  }
  const handleClick = () => {
    navigate("/book/ready-to-book")
  }
  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={0}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
    >
      <div>
        <EuiText className="eui-textLeft">
          <h1>Booking cancelled</h1>
          <p>Thank you for your response.</p>
          <EuiSpacer size="xs" />
        </EuiText>
        <EuiPanel>
          <EuiDescriptionList
            type="column"
            listItems={[
              {
                title: <EuiImage src={Images.Envelope} alt="Email" />,
                description: (
                  <EuiText grow={false}>
                    <strong>Check your inbox</strong>
                    <p>We have sent a confirmation of your cancellation.</p>
                  </EuiText>
                ),
              },
            ]}
          />
        </EuiPanel>
      </div>
    </HailCXLayout>
  )
}

export default observer(CancelConfirmPage)
